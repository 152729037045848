import request from '@/utils/request'
//获取pda前五十版本号
export function versionNum(params) {
  return request({
    url: '/HdybPdaVersion/GetAllPdaVersionList',
    method: 'get',
    params
  })
}
//添加pda版本号
export function addVersionNum(data) {
  return request({
    url: '/HdybPdaVersion/UpdateVersion',
    method: 'post',
    data
  })
}
//pc获取前五十版本号
export function versionNumPc(params) {
  return request({
    url: '/HdybPcVersion/GetAllPdaVersionList',
    method: 'get',
    params
  })
}
//添加pc版本号
export function addVersionNumPc(data) {
  return request({
    url: '/HdybPcVersion/UpdateVersion',
    method: 'post',
    data
  })
}

