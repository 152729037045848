var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-column" },
    [
      _c("sticky", { attrs: { className: "sub-navbar" } }, [
        _c(
          "div",
          { staticClass: "filter-container borderBotton" },
          [
            _c("permission-btn", {
              attrs: { moduleName: "wmsGoodsBatchMsts", size: "mini" },
              on: { "btn-event": _vm.onBtnClicked },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "app-container flex-item bgcolor" },
        [
          _c(
            "el-timeline",
            { staticClass: "centers" },
            _vm._l(_vm.activities, function (activity, index) {
              return _c(
                "el-timeline-item",
                {
                  key: index,
                  attrs: {
                    icon: activity.icon,
                    type: activity.type,
                    size: activity.size,
                    timestamp: activity.day,
                  },
                },
                [
                  _c("el-card", [
                    _c("h3", [_vm._v("版本号：" + _vm._s(activity.version))]),
                    _c("div", { staticStyle: { display: "flex" } }, [
                      _c("h4", { staticStyle: { width: "80px" } }, [
                        _vm._v("更新内容："),
                      ]),
                      _c(
                        "h4",
                        _vm._l(activity.remark, function (item, index) {
                          return _c("div", { key: index }, [
                            _vm._v(
                              " " +
                                _vm._s(index + 1) +
                                "、" +
                                _vm._s(item) +
                                " "
                            ),
                          ])
                        }),
                        0
                      ),
                    ]),
                  ]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [
            { name: "el-drag-dialog", rawName: "v-el-drag-dialog" },
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.$store.state.listLoading,
              expression: "$store.state.listLoading",
            },
          ],
          staticClass: "dialog-mini",
          attrs: {
            width: "40%",
            title: "更新PC版本信息",
            visible: _vm.pcversion,
          },
          on: {
            "update:visible": function ($event) {
              _vm.pcversion = $event
            },
            close: _vm.cleardata,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { padding: "20px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.versionData,
                "label-position": "right",
                "label-width": "60px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    size: "small",
                    label: "版本号",
                    prop: "version",
                    maxlength: "25",
                  },
                },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入版本号" },
                    model: {
                      value: _vm.versionData.version,
                      callback: function ($$v) {
                        _vm.$set(_vm.versionData, "version", $$v)
                      },
                      expression: "versionData.version",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  staticStyle: { height: "auto" },
                  attrs: { size: "small", label: "更新内容", prop: "remark" },
                },
                _vm._l(_vm.versionData.remark, function (item, index) {
                  return _c(
                    "div",
                    { key: index },
                    [
                      _c(
                        "el-input",
                        {
                          attrs: {
                            type: "input",
                            placeholder: "请输入更新内容",
                          },
                          model: {
                            value: _vm.versionData.remark[index],
                            callback: function ($$v) {
                              _vm.$set(_vm.versionData.remark, index, $$v)
                            },
                            expression: "versionData.remark[index]",
                          },
                        },
                        [
                          _c("template", { slot: "prepend" }, [
                            _vm._v(_vm._s(index + 1)),
                          ]),
                          (
                            _vm.versionData.remark.length == index + 1
                              ? true
                              : false
                          )
                            ? _c("el-button", {
                                attrs: { slot: "append", icon: "el-icon-plus" },
                                on: { click: _vm.addRemark },
                                slot: "append",
                              })
                            : _vm._e(),
                        ],
                        2
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "danger" },
                  on: {
                    click: function ($event) {
                      _vm.pcversion = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "primary" },
                  on: { click: _vm.createpc },
                },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }